import { Container, Grid } from '@material-ui/core'
import React from 'react'
import BmCard from '../../components/bm-card'
import Layout from '../../components/layout'
import PageHeader from '../../components/page-header'
import GridRow from '../../components/_grid-row'

//education images
import greyPlaceholder from '../../images/grey-placeholder.png';
import aliImg from '../../images/team/ali.jpg';
import nirvanaImg from '../../images/team/nirvana.jpg';
import lamiaaImg from '../../images/team/lamiaa.jpg';
import karinaImg from '../../images/team/karina.jpg';
import ramyImg from '../../images/team/ramy.jpg';
import nourImg from '../../images/team/nour.jpg';
import khadigaImg from '../../images/team/khadiga.jpg';

export default function Team() {
    return (
        <Layout pageName="Meet the Team">
            <Container maxWidth="xl">
                <GridRow>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={nirvanaImg} 
                                cardTitle={{title: 'Nirvana Zaher', titleLink: '/team/nirvana'}}  
                                desc="As concept creator and managing founder of Beach Monkeyz, Nirvana believes in a holistic & integrative approach to fitness."  
                                learnMore={{text: 'Learn more about Nirvana', link: '/team/nirvana'}}
                            />
                        </Grid>
                        
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={lamiaaImg}
                                cardTitle={{title: 'Lamiaa Mahmoud', titleLink: '/team/lamiaa'}}  
                                desc="Lamiaa uses her vast knowledge of energy healing and yoga to help others overcome and elevate their lives."  
                                learnMore={{text: 'Learn more about Lamiaa', link: '/team/lamiaa'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={aliImg}
                                cardTitle={{title: 'Ali El Khatib', titleLink: '/team/ali'}}  
                                desc="Ali is our go-to resident weight-lifting coach; he’s an expert at metabolic conditioning, strength exercise, and mobility training."  
                                learnMore={{text: 'Learn more about Ali', link: '/team/ali'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={karinaImg}
                                cardTitle={{title: 'Karina Sadovnichenko', titleLink: '/team/karina'}}  
                                desc="Karina is our yin yoga and vinyasa yoga specialist. She is passionate about teaching all styles of yoga, especially yin and restorative."  
                                learnMore={{text: 'Learn more about Karina', link: '/team/karina'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={ramyImg}
                                cardTitle={{title: 'Ramy Metwally', titleLink: '/team/ramy'}}  
                                desc="Ramy is our go-to resident boxing and boxercise coach. He is also a champion boxer, and ranked nationally."  
                                learnMore={{text: 'Learn more about Ramy', link: '/team/ramy'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={nourImg}
                                cardTitle={{title: 'Nour El Ganzouri', titleLink: '/team/nour'}}  
                                desc="Nour is our resident nutritionist at Beach Monkeyz. She educates and empowers people to understand and integrate mindful eating habits."  
                                learnMore={{text: 'Learn more about Nour', link: '/team/nour'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={khadigaImg}
                                cardTitle={{title: 'Khadiga Amin', titleLink: '/team/khadiga'}}  
                                desc="Egypt's first-ever Ironman finisher and now nationally accredited Triathlon Coach."  
                                learnMore={{text: 'Learn more about Khadiga', link: '/team/khadiga'}}
                            />
                        </Grid>
                    </Grid>
                </GridRow>
            </Container>
        </Layout>
    )   
}
